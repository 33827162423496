// Here you can add other styles
.c-app {
  background-color: #ebedef;
}

.table-center {
  align-items: center;
  display: flex;
  height: 35px;

  > label {
    margin-bottom: 0;
  }

  > p {
    margin-bottom: 0;
  }
}

.card-body {
  > div.form-group:last-of-type {
    margin-bottom: 0;
  }
}

html:not([dir="rtl"]) {
  select.form-control.is-invalid {
    background-position: right calc(1em + 0.1875rem) center;
  }
}

.tab-content {
  background: #ffffff;
  padding: 1rem;

  @media (max-width: 767.98px) {
    padding-bottom: 20px;
    padding-top: 8px;
  }
}

// Here you can add other styles
html,
body,
#root {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
}

.header {
  height: 64px;

  > .container {
    max-width: 100%;
  }
}

.overflow-auto {
  overflow: auto;
}

.c-main {
  overflow: auto;
}

.container-fluid {
  height: auto;
  min-height: 100%;
  overflow: auto;
  padding: 12px;
}

.body {
  height: calc(100% - 137px);

  .container-lg {
    height: 100%;
  }

  .container {
    max-width: 100%;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.flex {
  display: flex;
  flex: 1;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.leaflet-container {
  z-index: 0;
}

$multiplier: 4;
$sizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

@each $size in $sizes {
  .m-#{$size} {
    margin: #{$size * $multiplier}px;
  }

  .mx-#{$size} {
    margin-left: #{$size * $multiplier}px;
    margin-right: #{$size * $multiplier}px;
  }
  .my-#{$size} {
    margin-top: #{$size * $multiplier}px;
    margin-bottom: #{$size * $multiplier}px;
  }

  .ml-#{$size} {
    margin-left: #{$size * $multiplier}px;
  }

  .mt-#{$size} {
    margin-top: #{$size * $multiplier}px;
  }

  .mr-#{$size} {
    margin-right: #{$size * $multiplier}px;
  }

  .mb-#{$size} {
    margin-bottom: #{$size * $multiplier}px;
  }

  .p-#{$size} {
    padding: #{$size * $multiplier}px;
  }

  .px-#{$size} {
    padding-left: #{$size * $multiplier}px;
    padding-right: #{$size * $multiplier}px;
  }
  .py-#{$size} {
    padding-top: #{$size * $multiplier}px;
    padding-bottom: #{$size * $multiplier}px;
  }

  .pl-#{$size} {
    padding-left: #{$size * $multiplier}px;
  }

  .pt-#{$size} {
    padding-top: #{$size * $multiplier}px;
  }

  .pr-#{$size} {
    padding-right: #{$size * $multiplier}px;
  }

  .pb-#{$size} {
    padding-bottom: #{$size * $multiplier}px;
  }

  .h-#{$size} {
    height: #{$size * $multiplier}px;
  }

  .w-#{$size} {
    width: #{$size * $multiplier}px;
  }

  .font-#{$size} {
    font-size: #{$size * $multiplier}px;
  }

  .line-#{$size} {
    line-height: #{$size * $multiplier + $multiplier}px;
  }
}

$colors: "danger", "warning", "success";

@each $color in $colors {
  .btn-#{$color} {
    --cui-btn-color: #{$white};
    --cui-btn-active-color: #{$white};
    --cui-btn-disabled-color: #{$white};
    --cui-btn-hover-color: #{$white};
  }
}

:root {
  --cui-sidebar-bg: #121212;

  .sidebar {
    --cui-sidebar-nav-icon-width: 1rem;
  }
}

.payment-method-card > .card-body {
  padding: 0 !important;
  margin: 0;
}

.c-sidebar-nav-item {
  list-style: none;
}

html:not([dir="rtl"]) select.form-control.is-valid {
  background-position: right calc(1.375em + 0.1875rem) center;
}

.c-body {
  height: calc(100% - 64px);
}

.form-row {
  margin-top: 8px;
  height: 38px;

  &:first-of-type {
    margin-top: 0;
  }

  &.static {
    height: 38px;
  }

  > div {
    align-items: center;
    display: flex;

    .form-label {
      margin-bottom: 0;
    }

    .form-control-static {
      margin: 0;
    }
  }
}

.payment-widget {
  > .card-body {
    > div {
      margin: 0 16px 0 0 !important;
    }
  }
}

.table {
  td {
    vertical-align: middle;
  }
}

.mobile-hidden {
  @media (max-width: 767.98px) {
    display: none !important;
  }
}

.web-hidden {
  display: none !important;

  @media (max-width: 767.98px) {
    display: inherit !important;
  }
}

.flex-05 {
  flex: 0.5;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.file-input-row {
  padding-left: 12px;
  padding-right: 12px;

  > label {
    padding: 0;
  }
}

.stack-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.padding-2 > div {
  padding: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0rem;
}

.product-search .select-search-container {
  width: 100%;
}

a {
  text-decoration: none;
}

.only-print,
.only-print-draft {
  display: none;
}

.sale-note {
  height: 100px !important;
  resize: none;
}

@media print {
  html,
  body,
  #root,
  .c-app,
  .c-wrapper {
    overflow: visible !important;
    font-size: 12px !important;
  }

  .c-app {
    height: auto !important;
  }

  .only-print,
  .only-print-draft {
    display: block;
  }

  .no-print,
  .no-print-draft {
    display: none;
  }

  .sale-note {
    margin: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    height: auto !important;
  }

  .sale-note + .form-label {
    display: none;
  }

  .print-table {
    box-shadow: none;

    .card-body {
      padding: 0;
    }
  }
}

.simplebar-placeholder {
  height: 0;
  display: none;
}

.sidebar-nav .nav-group-items .nav-link {
  padding: var(--cui-sidebar-nav-link-padding-y)
    var(--cui-sidebar-nav-link-padding-x);
}

.sidebar-header {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-brand-full {
  display: flex;
}

.form-control.is-valid.with-validation {
  background-position: right calc(0.375em + 2.575rem) center;
}

.isP {
  background-color: #444;
}

#datepicker {
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0 0.375rem 0.375rem;
  background: #ffffff;
  border: 1px solid #e4e7ea;
  color: #5c6873;
  border-radius: 0.25rem;

  font-size: 0.875rem;
  text-align: left;
  text-overflow: ellipsis;

  .react-daterange-picker__wrapper {
    border: none;
  }

  .react-daterange-picker__inputGroup {
    text-align: center;
  }
}

.form-control.plain-input-title {
  border: 0;
  margin: 0;
  padding: 0;
  font-weight: 600;
  user-select: none !important;
  background: none !important;
}

.form-control.plain-input {
  border: 0;
  user-select: none !important;
  background: none !important;
}

.plain-button {
  border: 0;
  user-select: none !important;
  background: none !important;
}

.hidden {
  display: none;
}

.form-control {
  height: 35px;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  border: 1px solid #e4e7ea;
  font-family: "Inter var", sans-serif;
}

.date-picker-dropdown.show {
  z-index: 1056;
}

.cursor-pointer {
  cursor: pointer;
}

.no-inner-padding {
  * {
    padding: 0;
  }
}

.my-select {
  height: 35px;
  border-radius: 0.25rem;
  font-size: 14px;
}

.my-button {
  height: 35px;
  font-size: 14px;
}
